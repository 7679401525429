<template>
    <div class="container-fluid">
        <div class="card my-3">
            <div class="card-header d-inline-flex justify-content-between">
                <AdminSideBarToggle/>
                <el-input
                    style="max-width: 250px"
                    class="ms-auto me-4"
                    clearable
                    v-model="searchText"
                    prefix-icon="el-icon-search"
                    :placeholder="$t('search')"/>
                <el-button
                    @click="showInviteCreateDialog"
                    size="small"
                    type="primary">{{ $t('add') }}</el-button>
            </div>
            <!-- Invites table -->
            <div class="card-body">
                <el-table
                    v-loading="invitesLoadingState"
                    :data="filteredInvites"
                    style="width: 100%">
                    <el-table-column
                        prop="invite"
                        fixed="left"
                        min-width="150px"
                        :label="$t('invite')">
                    </el-table-column>
                    <el-table-column
                        prop="comment"
                        min-width="150px"
                        :label="$t('comment')">
                    </el-table-column>
                    <el-table-column
                        prop="user.email"
                        min-width="150px"
                        :label="$t('usedBy')">
                    </el-table-column>

                    <el-table-column
                        min-width="140px"
                        :label="$t('actions')">
                        <template slot-scope="scope">
                            <el-button
                                size="small"
                                type="primary"
                                @click="showInviteEditDialog(scope.row)">{{ $t('edit') }}</el-button>
                            <el-popconfirm
                                @confirm="deleteInvite(scope.row)"
                                :title="$t('areYouSure')"
                                :confirm-button-text="$t('yes')"
                                :cancel-button-text="$t('no')"
                                confirm-button-type="danger"
                            >
                                <el-button
                                    slot="reference"
                                    size="small"
                                    type="danger">{{ $t('delete') }}</el-button>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <!-- Invite edit dialog -->
        <el-dialog
            class="dialog"
            :title="inviteForm.invite_id ? $t('inviteEditing') : $t('inviteAdding')"
            :visible.sync="isInviteEditDialogVisible"
        >
            <el-form
                @submit.native.prevent
                label-position="left"
                ref="inviteForm"
                :rules="inviteFormRules"
                :model="inviteForm"
                label-width="150px">
                <el-form-item :label="$t('invite')" prop="invite">
                    <el-input
                        clearable
                        :disabled="!!inviteForm.invite_id"
                        v-model="inviteForm.invite"
                        :placeholder="$t('invite')">
                        <el-button
                            @click="generateNewInvite"
                            :disabled="!!inviteForm.invite_id"
                            slot="append"
                            icon="el-icon-refresh-right"></el-button>
                    </el-input>
                </el-form-item>
                <el-form-item :label="$t('comment')" prop="comment">
                    <el-input
                        clearable
                        v-model="inviteForm.comment"
                        :placeholder="$t('comment')">
                    </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="isInviteEditDialogVisible = false">{{ $t('close') }}</el-button>
                <el-button size="small" type="primary" @click="submitInviteForm" :loading="invitesLoadingState">{{ $t('save') }}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {generateRandomString} from "@/common/helpers"
import AdminSideBarToggle from '@/components/AdminSideBarToggle'

export default {
    name: "Invites",
    components: {
        AdminSideBarToggle,
    },
    i18n: {
        messages: {
            en: {
                invites: 'Invites',
                inviteEditing: 'Editing an invite',
                inviteAdding: 'Adding an invite',
                invite: 'Invite',
                comment: 'Comment',
                usedBy: 'Used by',

                actions: 'Actions',
                search: 'Search',
                add: 'Add',
                close: 'Close',
                save: 'Save',
                edit: 'Edit',
                delete: 'Delete',
                yes: 'Yes',
                no: 'No',
                areYouSure: 'Are you sure?',
                requiredFiled: 'Required field',
                error: 'Error',
                success: 'Success',
                inviteCreated: 'Invite created',
                inviteUpdated: 'Invite updated',
                inviteDeleted: 'Invite deleted',
                inviteCreateError: 'Error adding invite',
                inviteUpdateError: 'Error updating invite',
                inviteDeleteError: 'Error deleting invite',
            },
            ru: {
                invites: 'Инвайты',
                inviteEditing: 'Редактирование инвайта',
                inviteAdding: 'Добавление инвайта',
                invite: 'Инвайт',
                comment: 'Комментарий',
                usedBy: 'Использован',

                actions: 'Действия',
                search: 'Поиск',
                add: 'Добавить',
                close: 'Закрыть',
                save: 'Сохранить',
                edit: 'Изменить',
                delete: 'Удалить',
                yes: 'Да',
                no: 'Нет',
                areYouSure: 'Вы уверены?',
                requiredFiled: 'Поле обязательно для заполнения',
                error: 'Ошибка',
                success: 'Успешно',
                inviteCreated: 'Инвайт добавлен',
                inviteUpdated: 'Инвайт обновлен',
                inviteDeleted: 'Инвайт удален',
                inviteCreateError: 'Ошибка добавления инвайта',
                inviteUpdateError: 'Ошибка обновления инвайта',
                inviteDeleteError: 'Ошибка удаления инвайта',
            }
        }
    },
    data: function () {
        return {
            searchText: '',
            inviteForm: {
                invite_id: null,
                invite: '',
                comment: ''
            },
            inviteFormRules: {
                invite: [
                    {
                        required: true,
                        message: () => this.$t('requiredFiled')
                    },
                ]
            },
            isInviteEditDialogVisible: false,
        }
    },
    computed: {
        invitesLoadingState: function () {
            return this.$store.getters['admin/invitesLoadingState']
        },
        invites: function () {
            return this.$store.getters['admin/invites']
        },
        filteredInvites: function () {
            // Filter by invite and comment
            return this.invites.filter(invite => {
                return invite.invite.toUpperCase().includes(this.searchText.toUpperCase()) || (invite.comment && invite.comment.toUpperCase().includes(this.searchText.toUpperCase()))
            })
        },
    },
    methods: {
        generateNewInvite: function () {
            this.inviteForm.invite = generateRandomString(6)
        },
        showInviteCreateDialog: function () {
            this.inviteForm.invite_id = null
            this.inviteForm.invite = ''
            this.inviteForm.comment = ''

            this.generateNewInvite()
            this.isInviteEditDialogVisible = true
        },
        showInviteEditDialog: function (invite) {
            this.inviteForm.invite_id = invite.invite_id
            this.inviteForm.invite = invite.invite
            this.inviteForm.comment = invite.comment

            this.isInviteEditDialogVisible = true
        },
        submitInviteForm: function () {
            this.$refs['inviteForm'].validate(valid => {
                if(!valid) {
                    return false
                }

                if(this.inviteForm.invite_id) {
                    // Update invite
                    this.$store.dispatch('admin/invitesUpdateRequest', this.inviteForm)
                        .then(() => {
                            this.$notify({
                                type: 'success',
                                title: this.$t('success'),
                                message: this.$t('inviteUpdated'),
                            })

                            this.isInviteEditDialogVisible = false
                        })
                        .catch(() => {
                            this.$notify({
                                type: 'error',
                                title: this.$t('error'),
                                message: this.$t('inviteUpdateError')
                            })
                        })
                } else {
                    // Create new invite
                    this.$store.dispatch('admin/invitesCreateRequest', this.inviteForm)
                        .then(() => {
                            this.$notify({
                                type: 'success',
                                title: this.$t('success'),
                                message: this.$t('inviteCreated'),
                            })

                            this.isInviteEditDialogVisible = false
                        })
                        .catch(() => {
                            this.$notify({
                                type: 'error',
                                title: this.$t('error'),
                                message: this.$t('inviteCreateError')
                            })
                        })
                }
            })
        },
        reloadInvites: function () {
            this.$store.dispatch('admin/invitesGetRequest')
                .catch(() => {
                    this.$notify({
                        type: 'error',
                        title: 'Ошибка',
                        message: 'Ошибка загрузки инвайтов'
                    })
                })
        },
        deleteInvite: function (invite) {
            this.$store.dispatch('admin/invitesDeleteRequest', invite)
                .then(() => {
                    this.$notify({
                        type: 'success',
                        title: this.$t('success'),
                        message: this.$t('inviteDeleted'),
                    })
                })
                .catch(() => {
                    this.$notify({
                        type: 'error',
                        title: this.$t('error'),
                        message: this.$t('inviteDeleteError')
                    })
                })
        },
    },
    mounted() {
        this.reloadInvites()
    }
}
</script>

<style scoped>
.el-button+span {
    margin-left: 10px;
}
</style>
